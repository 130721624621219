import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Digital Marketing Company in Delhi NCR, India, USA & UK"
      description="Digital Marketing is one of the core services offered by D2i. D2i is one of the leading company in India specializing in Digital Marketing."
    />
    <div style={{ textAlign: "justify" }}>
    <div className="container py-5">
      <div className="row">
        <div className="col-12">
          <div className="section-title">
            <h1>Digital Marketing</h1>
          </div>
          <p>
            <strong>Digital marketing</strong> is the use of the
            Internet, mobile devices, social media, search engines, and other
            channels to reach consumers. Some marketing experts consider digital
            marketing to be an entirely new endeavor that requires a new way of
            approaching customers and new ways of understanding how customers
            behave compared to traditional marketing.
          </p>
         
          <h2 className="cloudhead h3">Key Takeaways</h2>
          <ul className="mb-4">
            <li>
              Digital marketing is the use of the Internet to reach consumers.
            </li>
            <li>
              Digital marketing is a broad field, including attracting customers
              via email, content marketing, search platforms, social media, and
              more.
            </li>
          </ul>

          <h2 className="cloudhead h3">Our approach for SEO (Search Engine Optimization)</h2>
          
          <h3 className="cloudhead h4">ON Page Optimization:</h3>
          <ul>
            <li> Website Analysis</li>
            <li> Keyword Analysis</li>
            <li> Title and Meta Tag Optimization</li>
            <li> Description Tag Optimization</li>
            <li> Competitor Analysis</li>
            <li> Checking the canonicalization Error</li>
            <li> Website URL Redirection</li>
            <li> URL Optimization</li>
            <li> Image Optimization</li>
            <li> AMP site optimization for mobile users</li>
          </ul>
  
          <h3 className="cloudhead h4">OFF Page Optimization:</h3>
          <ul>
            <li> Search Engine Submission</li>
            <li> Article Submission</li>
            <li> Social Profile</li>
            <li> Social Bookmarking</li>
            <li> Blog Submission</li>
            <li> Local Listing</li>
            <li> Classified Submission</li>
            <li> Video Submission</li>
            <li> PDF Sharing</li>
            <li> Image Sharing</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  </Layout>
)
